import { useState } from "react";
import { AppShell } from "@mantine/core";
import {
  IconBellRinging,
  IconUsers,
  IconHome,
  IconClipboardList,
  IconUsersGroup,
  IconListCheck,
  IconChartBar,
  IconFileUpload,
} from "@tabler/icons-react";

import { useUser } from "../../contexts/UserContext";
import { ROLES } from "../../constants/common.constants";
import { NavbarCollapsed } from "./NavbarCollapsed";
import { NavbarExpanded } from "./NavbarExpanded";
import { useNavigate } from "react-router-dom";
import React from "react";
import { getWebRoutes } from "../../routes/RouteImports";
import { checkExtensionStatus } from "../../utils/extensionUtil";
import toast from "react-hot-toast";

const data = [
  {
    link: "/",
    label: "Home",
    icon: IconHome,
    disabled: false,
    roles: [ROLES.AGENT],
  },
  {
    link: "/analytics",
    label: "Analytics",
    icon: IconChartBar,
    disabled: false,
    roles: [ROLES.ADMIN],
  },
  {
    link: "/applications",
    label: "Applications",
    icon: IconClipboardList,
    disabled: false,
    roles: [ROLES.AGENCY, ROLES.AGENT, ROLES.REVIEWER],
  },
  // {
  //   link: "/fileUpload",
  //   label: "File Upload",
  //   icon: IconFileUpload,
  //   disabled: false,
  //   roles: [ROLES.APPLICANT],
  // },
  {
    link: "/agents",
    label: "Agents",
    icon: IconUsers,
    disabled: false,
    roles: [ROLES.AGENCY],
  },
  {
    link: "/applicants",
    label: "Applicants",
    icon: IconUsersGroup,
    disabled: false,
    roles: [ROLES.AGENCY, ROLES.AGENT],
  },
  {
    link: "/agency",
    label: "Agencies",
    icon: IconUsers,
    disabled: false,
    roles: [ROLES.ADMIN],
  },
  {
    link: "/reviewers",
    label: "Reviewers",
    icon: IconListCheck,
    disabled: false,
    roles: [ROLES.ADMIN, ROLES.AGENCY],
  },
  {
    link: "/notifications",
    label: "Notifications",
    icon: IconBellRinging,
    disabled: false,
    roles: [ROLES.ADMIN, ROLES.AGENCY, ROLES.AGENT],
  },
  // {
  //   link: "/settings",
  //   label: "Settings",
  //   icon: IconSettings,
  //   disabled: true,
  //   roles: [ROLES.ADMIN, ROLES.AGENCY, ROLES.AGENT, ROLES.APPLICANT],
  // },
];

export type NavbarProps = {
  data: Array<any>;
  expanded: boolean;
  setExpanded: (expanded: boolean) => void;
  navigate: ReturnType<typeof useNavigate>;
  logoutCurrentUser: () => void;
  getCurrentUserRoles: () => Array<string>;
  active: string;
  setActive: (active: string) => void;
  extensionStatus: "installed" | "not-installed";
  handleExtensionDownload?: () => void;
};

export function NavbarApp(props = { children: <></> }) {
  const [active, setActive] = useState("");
  const navigate = useNavigate();
  const { logoutCurrentUser, getCurrentUserRoles } = useUser();
  const [isExpanded, setIsExpanded] = useState(true);
  const [extensionStatus, setExtensionStatus] = React.useState<
    "installed" | "not-installed"
  >("not-installed");

  const handleExtensionDownload = async () => {
    const origin = window.location.origin;
    let fileUrl = "";

    if (origin.includes("beta")) {
      fileUrl = `${origin}/iv-chrome-ext-beta-build.zip`;
    } else if (origin.includes("alpha")) {
      fileUrl = `${origin}/iv-chrome-ext-alpha-build.zip`;
    } else {
      toast.error("Invalid environment. Extension download failed.");
    }

    if (fileUrl) {
      try {
        const res = await fetch(fileUrl);
        if (!res.ok) {
          throw new Error(`Failed to fetch file: ${res.statusText}`);
        }
        const blob = await res.blob();

        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = fileUrl.split("/").pop() || "file";
        document.body.appendChild(a);
        a.click();
        a.remove();

        window.URL.revokeObjectURL(url);
        toast.success("Download completed successfully!", {
          position: "top-right",
        });
      } catch (error) {
        console.error("Error downloading the file:", error);
        toast.error("Error downloading the file.", {
          position: "top-right",
        });
      }
    } else {
      console.error("File URL is not set.");
    }
  };

  React.useEffect(() => {
    async function checkExtension() {
      const status = await checkExtensionStatus();
      if (status === true) {
        setExtensionStatus("installed");
      } else {
        setExtensionStatus("not-installed");
      }
    }
    checkExtension();
  }, []);

  React.useEffect(() => {
    const currentRoute = window.location.hash.split("#/")[1];
    const knownRoutes = getWebRoutes().map((route) => route?.path);

    if (
      currentRoute?.includes("form") ||
      currentRoute?.includes("fileUpload")
    ) {
      navigate(`/${currentRoute}`);
    } else if (!knownRoutes.includes(`/${currentRoute}`)) {
      const initialRoute = (() => {
        const role = getCurrentUserRoles!()?.[0];
        switch (role) {
          case ROLES.ADMIN:
            return "/agency";
          case ROLES.AGENCY:
            return "/agents";
          case ROLES.REVIEWER:
            return "/applications";
          case ROLES.AGENT:
            return "/applications";
          // TODO: Handle for APPLICANT
          default:
            return "/";
        }
      })();

      const activeLink = data.find(
        (item) => item.link === initialRoute.split("/")?.[1]
      )?.label!;

      navigate(initialRoute);
      setActive(activeLink);
    } else {
      const link = "/" + currentRoute?.split("/")?.[0];
      const activeLink = data.find((item) => item.link === link)?.label!;
      setActive(activeLink);
      navigate(link);
    }
  }, []);

  return (
    <AppShell
      navbar={{
        width: isExpanded ? 300 : 80,
        breakpoint: "sm",
      }}
      padding="md"
    >
      <AppShell.Navbar
        style={{
          backgroundColor: "white",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.1)",
        }}
      >
        {isExpanded ? (
          <NavbarExpanded
            data={data}
            extensionStatus={extensionStatus}
            expanded={isExpanded}
            setExpanded={setIsExpanded}
            navigate={navigate}
            logoutCurrentUser={logoutCurrentUser!}
            getCurrentUserRoles={getCurrentUserRoles!}
            active={active}
            setActive={setActive}
            handleExtensionDownload={handleExtensionDownload}
          />
        ) : (
          <NavbarCollapsed
            data={data}
            extensionStatus={extensionStatus}
            expanded={isExpanded}
            setExpanded={setIsExpanded}
            navigate={navigate}
            logoutCurrentUser={logoutCurrentUser!}
            getCurrentUserRoles={getCurrentUserRoles!}
            active={active}
            setActive={setActive}
            handleExtensionDownload={handleExtensionDownload}
          />
        )}
      </AppShell.Navbar>
      <AppShell.Main>{props.children}</AppShell.Main>
    </AppShell>
  );
}
